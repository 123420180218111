"use-strict";

//Dependencies
import React, { useState } from "react";

// React Native Component (Private Scope)=
import {
  H1,
  Image,
  CustomTextInput,
  PrimaryCta,
  Medium,
  UnderLinedLinkCta,
  CustomCheckBox,
  CustomText,
  BorderedDropdown,
  CustomDropdownOptions,
  LinkCta,
} from "../../components";

// Import Redux State (Private scope)
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as CampaignData from "../../common/data/campaign";
import * as Actions from "../../redux/actions";

import validator from "validator";

// Core Sdk (Private Scope)
import Core from "../../common/clientSdk.js";
// import { toast } from "react-hot-toast";
import { useSnackbar } from "react-simple-snackbar";
import Icon from "../../components/core_component/icon.js";
import LeftSideSignup from "./leftSideSignup.js";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { MdWhatsapp } from "react-icons/md";
import Select from "react-dropdown-select";
import countryList from "../../common/data/country";
import Utils from "../../common/utils.js";

const BrandSigninScreen = function (props) {
  const [loading, setLoading] = useState(false);
  const [user_name, setUsername] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [user_type, setUserType] = useState("agency_manager");
  const [work_email, setWorkEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCountryCode] = useState([]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [timeZone, setTimeZone] = useState([]);
  const [timeZoneSelected, setTimeZoneSelected] = useState();
  const [country, setCountry] = useState(["IN"]);
  const [countrySelected, setCountrySelected] = useState([
    {
      name: "India",
      calling_code: "+91",
      country_code: "IN",
      currency: {
        code: "INR",
        name: "Indian Rupee",
        symbol: "₹",
      },
    },
  ]);

  const [error, set_error] = useState({
    company_name: "",
    user_name: "",
    user_type: "",
    phone_number: "",
    email: "",
    password: "",
    designation: "",
    time_zone: "",
  });

  //console.log('demo url', URL.createObjectURL);

  // var dropdownData=['Brand', 'Agency']

  // var redux_selector  = useSelector((state)=>{
  //   return state
  // })

  var navigate = useNavigate();

  var redux_dispatch = useDispatch();
  console.log(removePlusSign(phone_number));
  console.log("phone_number", phone_number);

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (phone_number) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }

  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter full name");
    } else {
      set_error({ ...error, user_name: "" });
      return true;
    }
  }
  console.log("timeZone", timeZone.toString());

  function validateTimeZone(value) {
    if (timeZone.length == 0) {
      openSnackbar("Please select time zone");
      return false;
    }

    return true;
  }

  function validateCompanyName(value) {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter company name");
    } else {
      set_error({ ...error, company_name: "" });
      return true;
    }
  }

  function validateDesignation(value) {
    if (validator.isEmpty(value, {})) {
      openSnackbar("Please enter your designation");
    } else {
      set_error({ ...error, designation: "" });
      return true;
    }
  }

  function validateUserType(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, user_type: "Please select atleast one" });
    } else {
      set_error({ ...error, user_type: "" });
      return true;
    }
  }

  function validatePhone(value) {
    if (!phone_number) {
      openSnackbar("Please enter a valid phone number");
    } else {
      set_error({ ...error, phone_number: "" });
      return true;
    }
  }

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      openSnackbar("Please enter a valid email");
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }

  function validatePassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      openSnackbar("Password should of minimum 8 letters");
    } else {
      set_error({ ...error, password: "" });
      return true;
    }
  }

  console.log("timeZone", timeZone);

  /********************************************************************
  Brand Signup

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function brandSignup(progress_cb) {
    var params = {
      org_data: JSON.stringify({
        name: company_name,
        type: user_type,
        country: country.toString(),
      }),
      user_data: JSON.stringify({
        name: user_name,
        phone_country: "in",
        phone_number: removePlusSign(phone_number),
        email: work_email,
        password: password,
        designation: designation,
        country: country.toString(),
        time_zone: timeZone?.toString(),
      }),

      // address_data: JSON.stringify({
      //   address_premise: "haldwani",
      //   address_street: "Near Hanuman Mandir",
      //   address_city: "Noida",
      //   address_state: "UP",
      //   address_country: "India",
      // }),
      address_data: JSON.stringify({}),
    };
    if (
      validateName(user_name) &&
      validateCompanyName(company_name) &&
      validatePhone(phone_number) &&
      validateTimeZone() &&
      //validateUserType(user_type) &&
      validateEmail(work_email) &&
      validatePassword(password)
      // validateDesignation(designation)
    ) {
      // validationHandler(params);
      Core.brandSignup(
        brandSignupSuccess,
        brandSignupFailed,
        progress_cb,
        params
      );
    }

    console.log("params", params);
  }

  /********************************************************************
  Brand Signup success

  @param {Set} response_data - consisting user_data and brand_data

  No return
  *********************************************************************/
  function brandSignupSuccess(response_data) {
    if (response_data.org_data["org_data"]["type"] == "agency_manager") {
      navigate("/add-brand-details", {
        state: response_data.org_data,
      });
    } else {
      navigate("/add-brand-details");
    }

    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth_id },
    });
  }

  /********************************************************************
  Brand Signup Failed

  @param {Array} error_list - consisting Error List

  No return
  *********************************************************************/
  function brandSignupFailed(error_list) {
    showError(error_list.map((item, index) => item.m).join(", "));
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    setLoading(status);
  }

  function showError(message) {
    openSnackbar(message);
  }
  function updateCounty(selectedItem) {
    console.log("selectedItem", selectedItem);
    setCountrySelected(selectedItem);

    setCountry(
      selectedItem ? selectedItem.map((option) => option.country_code) : []
    );
  }

  function updateCountyTime(selectedItem) {
    console.log("selectedItem", selectedItem);

    setTimeZone(selectedItem);
    setTimeZone(selectedItem ? selectedItem.map((option) => option.value) : []);
  }

  const timezoneData = Object.values(Utils.getTimeZone(country.toString())).map(
    (timezone) => ({
      label: timezone,
      value: timezone,
    })
  );
  console.log("timezoneData", timezoneData);
  return (
    <div className="main-container">
      <div className="content-sign-container">
        <LeftSideSignup />
        <div className="auth-right-sign-container">
          <div className="inner-sign-left">
            <div className="inner-left-container-bind">
              <div className="flex-row logo">
                {/* <Image
                  source={"../assets/images/reelax-new-logo.svg"}
                  height={49}
                /> */}
              </div>
              <div className="sign-up-logo">
                <CustomText
                  h5
                  className="h5text"
                  //fontSize={"font_size_25"}
                  text={`Sign up as Brand`}
                  textColor={"text_color_primary"}
                  //fontWeight={"Poppins_regular_medium"}
                />
                {/* <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`Step 1 of 2`}
                  textColor={"text_color_secondary"}
                  fontWeight={"Poppins_regular_medium"}
                /> */}
                <div className="create_profile_step">
                  Step <span>1</span> of 2
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Name`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />

                <div className="m-t-4 ">
                  <CustomTextInput
                    className="custom-input-text"
                    placeholder="Enter your full name"
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={(e) => validateName(e.target.value)}
                  />
                </div>
              </div>

              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Phone Number`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width sing-up-number">
                  {/* <CustomTextInput
                    className="custom-input-text"
                    maxLength={10}
                    placeholder="Phone number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onBlur={(e) => validatePhone(e.target.value)}
                  /> */}
                  <PhoneInput
                    defaultCountry="IN"
                    value={phone_number}
                    onChange={setPhoneNumber}
                    placeholder="Enter phone number"
                  />
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Country`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width sing-up-number">
                  <Select
                    multi={false}
                    options={Object.values(countryList)}
                    values={countrySelected}
                    onChange={(value) => updateCounty(value)}
                    placeholder="Select County"
                    labelField="name"
                    valueField="country_code"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(230, 231, 232)",
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      paddingBottom: "16px",
                      paddingTop: "16px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Time Zone`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width sing-up-number">
                  <Select
                    multi={false}
                    options={timezoneData}
                    values={timeZoneSelected}
                    onChange={(value) => updateCountyTime(value)}
                    placeholder="Select Time Zone"
                    // labelField="name"
                    // valueField="country_code"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(230, 231, 232)",
                      backgroundColor: "#fff",
                      borderRadius: 8,
                      paddingBottom: "16px",
                      paddingTop: "16px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              </div>

              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Email`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    placeholder="Enter your business email"
                    onChange={(e) => setWorkEmail(e.target.value)}
                    onBlur={(e) => validateEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Company Name`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    placeholder="Enter company name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    onBlur={(e) => validateCompanyName(e.target.value)}
                  />
                </div>
              </div>
              <div className="m-t-16">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Password`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                />
                <div className="m-t-4 full-width">
                  <CustomTextInput
                    className="custom-input-text"
                    type="password"
                    placeholder="Create a password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => validatePassword(e.target.value)}
                  />
                </div>
              </div>

              {/* <div className="m-t-16 full-width">
                <BorderedDropdown
                  onChange={(e) => set_user_type(e.target.value)}
                  required
                  onBlur={(e) => validateUserType(e.target.value)}>
                  <option className="company-option" value="">
                    Company Type
                  </option>
                  {Object.values(CampaignData.USER_TYPE).map((item, index) => {
                    return (
                      <CustomDropdownOptions
                        title={item.n}
                        value={Object.keys(CampaignData.USER_TYPE)[index]}
                      />
                    );
                  })}
                </BorderedDropdown>
                <h5 className="error">{error.user_type}</h5>
              </div> */}
              {/* <div className="m-t-16 full-width">
                <CustomTextInput
                  placeholder="Designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  onBlur={(e) => validateDesignation(e.target.value)}
                />
                <p className="error">{error.designation}</p>
              </div> */}

              <div className="m-t-16 full-width">
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Next"}
                  //disabled={!term_condition} // || !sent_notification
                  isLoading={loading}
                  onClick={() => {
                    brandSignup(updateProgress);
                  }}
                />
              </div>

              <div className="m-t-6 signup-login-text">
                <CustomText
                  label
                  fontSize={"font_size_small"}
                  text={`If you already have an account`}
                  textColor={"text_color_secondary"}
                  fontWeight={"rubik_regular"}
                />
                &nbsp;
                <LinkCta
                  fontSize={"font_size_small"}
                  text={"Login here"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </div>
              <div className="m-t-24">
                <CustomText
                  p
                  fontSize={"font_size_extraSmall"}
                  text={`By continuing, you confirm that you have read, understood, and agreed to our`}
                  textColor={"text_grey_color"}
                  fontWeight={"rubik_regular"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <UnderLinedLinkCta
                  fontSize={"font_size_extraSmall"}
                  text={"Terms of Service"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/terms");
                  }}
                />
                <div style={{ marginLeft: 5, marginRight: 5 }}>
                  <CustomText
                    p
                    fontSize={"font_size_extraSmall"}
                    text={` and `}
                    textColor={"text_color_secondary"}
                    fontWeight={"rubik_regular"}
                  />
                </div>

                <UnderLinedLinkCta
                  fontSize={"font_size_extraSmall"}
                  text={"Privacy Policy"}
                  textColor={"text_color_register"}
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                />
              </div>
              <div
                className="m-t-40"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`Powered By Bridgeness Technologies Pvt Ltd`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandSigninScreen;
