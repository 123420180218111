import React, { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { MdDescription } from "react-icons/md";
import StepWizard from "react-step-wizard";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, FormGroup, Label, Input } from "reactstrap";
import { CustomText, Image, PrimaryCta } from "../../components";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";

function CampaignType(props) {
  const campaign_detail = props.campaignDetail;
  const navigate = useNavigate();
  const campaignType = campaign_detail?.type;

  const [selectedRole, setSelectedRole] = useState();

  const [selectedReward, setSelectedReward] = useState("");
  const [selectedEngagement, setSelectedEngagement] = useState("AUCTION");

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleRewardChange = (event) => {
    setSelectedReward(event.target.value);
  };
  const handleEngagementChange = (event) => {
    setSelectedEngagement(event.target.value);
  };

  const handleNext = () => {
    if (!formValidate()) {
      return false;
    }
    props.setCampaignDeepData((prev_state) => {
      return {
        ...prev_state,

        campaign_data: {
          ...prev_state.campaign_data,
          type: selectedRole,
          collab_type: selectedReward,
          engagement_type: selectedEngagement,
        },
      };
    });
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  function formValidate() {
    if (!selectedRole) {
      toast("Please select campaign type");
      return false;
    }
    if (!selectedEngagement) {
      toast("Please select engagement type");
      return false;
    }
    if (!selectedReward) {
      toast("Please select reward type");
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="type-campaign-container flex-column">
        {/* <div>
          <div>
            <CustomText
              p
              className="button2"
              text={"Reward Type"}
              fontWeight={"Poppins_semiBold"}
            />
          </div>
          <label className="label-cursor">
            <div className="flex-row m-t-8">
              <div className="type-campaign">
                <input
                  className="select-radio-btn"
                  type="radio"
                  name="role"
                  value="social_media"
                  id="one"
                  checked={selectedReward === "social_media"}
                  onChange={handleRewardChange}
                />
                <div className="type-media">
                  <div>
                    <CustomText
                      p
                      className="button2"
                      text={"Cash Campaign"}
                      fontWeight={"Poppins_semiBold"}
                    />
                  </div>
                  <div className="m-t-8">
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`The creator will showcase your brand and product`}
                    />
                  </div>
                </div>
              </div>
              <label className="label-cursor">
                <div className="type-campaign m-l-23">
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="role"
                    value="content"
                    id="two"
                    checked={selectedReward === "content"}
                    onChange={handleRewardChange}
                  />

                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"Barter Campaign"}
                      fontWeight={"Poppins_semiBold"}
                    />

                    <div className="m-t-8">
                      <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`The creator will promote your brand and product by`}
                      />
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </label>
        </div> */}
        <div>
          <div>
            <CustomText
              p
              className="button2"
              text={"Campaign Type"}
              fontWeight={"Poppins_semiBold"}
            />
          </div>

          <div style={{ flex: 1 }} className="flex-row m-t-8">
            <label
              style={{ flex: 0.5, marginRight: 23 }}
              id="social_media"
              className="label-cursor"
            >
              <div
                className={
                  selectedRole == "social_media"
                    ? "type-campaign-active"
                    : "type-campaign"
                }
              >
                <input
                  className="select-radio-btn"
                  type="radio"
                  name="social_media"
                  value="social_media"
                  id="social_media"
                  checked={selectedRole === "social_media"}
                  onChange={handleRoleChange}
                />
                <div className="type-media">
                  <div>
                    <CustomText
                      p
                      className="button2"
                      text={"Social Media Campaign"}
                      fontWeight={"Poppins_semiBold"}
                    />
                  </div>
                  <div className="m-t-8">
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`The influencer will share the post on their social media account and tag you on the post`}
                      className="caption_2 "
                    />
                    {/* <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      className="caption_2 "
                      text={`through posts on their social media account.`}
                    /> */}
                  </div>
                </div>
              </div>
            </label>
            <label style={{ flex: 0.5 }} id="content" className="label-cursor">
              <div
                className={
                  selectedRole == "content"
                    ? "type-campaign-active "
                    : "type-campaign "
                }
              >
                <input
                  className="select-radio-btn"
                  type="radio"
                  name="content"
                  value="content"
                  id="content"
                  checked={selectedRole === "content"}
                  onChange={handleRoleChange}
                />

                <div className="type-media">
                  <CustomText
                    p
                    className="button2"
                    text={"UGC Campaign"}
                    fontWeight={"Poppins_semiBold"}
                  />

                  <div className="m-t-8">
                    <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      text={`Influencer will share raw file with you, You can use the video or Image as per your requirement`}
                      className="caption_2 "
                    />
                    {/* <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`posting and sharing media files on the Reelax platform.`}
                        className="caption_2 "
                      /> */}
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div>
        {/* {selectedRole && (
          <div>
            <CustomText
              p
              className="button2"
              text={"Engagement Type"}
              fontWeight={"Poppins_semiBold"}
            />
            <div style={{ flex: 1 }} className="flex-row m-t-8">
              <label
                style={{ flex: 0.5, marginRight: 23 }}
                id="AUCTION_ENGAGEMENT"
                className="label-cursor"
              >
                <div
                  className={
                    selectedEngagement == "AUCTION"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="AUCTION_ENGAGEMENT"
                    value="AUCTION"
                    id="AUCTION_ENGAGEMENT"
                    checked={selectedEngagement === "AUCTION"}
                    onChange={handleEngagementChange}
                  />
                  <div className="type-media">
                    <div>
                      <CustomText
                        p
                        className="button2"
                        text={"Public"}
                        fontWeight={"Poppins_semiBold"}
                      />
                    </div>
                    <div className="m-t-8">
                      <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`Influencer approaches you `}
                        className="caption_2 "
                      />
                    </div>
                  </div>
                </div>
              </label>
              <label style={{ flex: 0.5 }} id="INVITE" className="label-cursor">
                <div
                  className={
                    selectedEngagement == "INVITE"
                      ? "type-campaign-active "
                      : "type-campaign "
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="INVITE"
                    value="INVITE"
                    id="INVITE"
                    checked={selectedEngagement === "INVITE"}
                    onChange={handleEngagementChange}
                  />

                  <div className="type-media">
                    <CustomText
                      p
                      className="button2"
                      text={"Private"}
                      fontWeight={"Poppins_semiBold"}
                    />

                    <div className="m-t-8">
                      <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`You approach to Influencers`}
                        className="caption_2 "
                      />
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        )} */}
        {selectedRole && (
          <div>
            <div>
              <CustomText
                p
                className="button2"
                text={"Payment Type"}
                fontWeight={"Poppins_semiBold"}
              />
            </div>

            <div style={{ flex: 1 }} className="flex-row m-t-8">
              <label
                style={{ flex: 0.5 }}
                id="AUCTION"
                className="label-cursor"
              >
                <div
                  className={
                    selectedReward == "AUCTION"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="AUCTION"
                    value="AUCTION"
                    id="AUCTION"
                    checked={selectedReward === "AUCTION"}
                    onChange={handleRewardChange}
                  />
                  <div className="type-media">
                    <div>
                      <CustomText
                        p
                        className="button2"
                        text={"Cash "}
                        fontWeight={"Poppins_semiBold"}
                      />
                    </div>
                    <div className="m-t-8">
                      <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`Influencer will receive cash after content approval`}
                        className="caption_2 "
                      />
                      {/* <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      className="caption_2 "
                      text={`through posts on their social media account.`}
                    /> */}
                    </div>
                  </div>
                </div>
              </label>
              <label
                style={{ flex: 0.5, marginLeft: 23 }}
                id="BARTER"
                className="label-cursor"
              >
                <div
                  className={
                    selectedReward == "BARTER"
                      ? "type-campaign-active"
                      : "type-campaign"
                  }
                >
                  <input
                    className="select-radio-btn"
                    type="radio"
                    name="BARTER"
                    value="BARTER"
                    id="BARTER"
                    checked={selectedReward === "BARTER"}
                    onChange={handleRewardChange}
                  />
                  <div className="type-media">
                    <div>
                      <CustomText
                        p
                        className="button2"
                        text={"Barter "}
                        fontWeight={"Poppins_semiBold"}
                      />
                    </div>
                    <div className="m-t-8">
                      <CustomText
                        p
                        textColor={"text_color_black"}
                        fontSize={"font_size_regular"}
                        text={`Influencer will create content for delivered/purchased product`}
                        className="caption_2"
                      />
                      {/* <CustomText
                      p
                      textColor={"text_color_black"}
                      fontSize={"font_size_regular"}
                      className="caption_2 "
                      text={`through posts on their social media account.`}
                    /> */}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        )}
      </div>
      {/* <hr /> */}
      <div className="divide-line"></div>
      <div className="type-campaign-btn">
        <PrimaryCta
          invert
          className="back-btn-campaign"
          onClick={() => navigate("/")}
          fontSize={"font_size_large"}
          text={"Cancel"}
        />

        <PrimaryCta
          fifth
          onClick={handleNext}
          fontSize={"font_size_large"}
          text={"Next"}
        />
      </div>
    </>
  );
}

export default CampaignType;
