import React from "react";
import { TransitionHistory, CustomText, FallBack } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import ReportSidebar from "../report-sidebar";
import { useSelector } from "react-redux";
const limit = 10;
function PayoutListScreen() {
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(0);
  const [payoutList, setPayoutList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openSnackbar] = useSnackbar();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;
  const { org_id = {} } = brand_data;

  useEffect(() => {
    getPayoutList(start, updateProgress);
  }, []);

  function updateProgress(status) {}

  function getPayoutList(startPage, progress_cb) {
    var params = {
      limit: limit,
      start: startPage,
    };
    Core.getPayoutList(
      getPayoutListSuccess,
      getPayoutListFailed,
      progress_cb,
      params
    );
  }

  function getPayoutListSuccess(response_data) {
    if (next == 0 || next == null) {
      // API called first time
      setPayoutList(response_data["payout_data"]);
    } else {
      setPayoutList([...payoutList, ...response_data["payout_list"]]);
    }
    setNext(response_data?.pagination_data?.next);
    setLoading(false);
  }

  function getPayoutListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div className="page-container">
          {loading ? (
            <div className="loader-mid">
              <Loader />
            </div>
          ) : (
            <>
              <div className="team-member-header">
                <CustomText
                  p
                  text={"Payout History"}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_2xl"}
                />
              </div>
              <InfiniteScroll
                dataLength={payoutList && payoutList.length}
                next={() => getPayoutList(next, updateProgress)}
                hasMore={next !== null}
                loader={""}
              >
                {payoutList && payoutList.length !== 0 ? (
                  <div class="content-row-parent">
                    {payoutList &&
                      payoutList.map((item) => {
                        const formattedAmount = parseFloat(
                          item.payout_amount
                        ).toFixed(2);

                        return (
                          <div className="content-row">
                            <TransitionHistory
                              Detail={` Paid to creator on ${Utils.convertUnixToDate(
                                item.date_added,
                                user_data.time_zone
                              )}`}
                              id_title={"Contract ID:"}
                              Id={item.assignment_id.toUpperCase()}
                              AmountColor={"text_credit_color"}
                              signColor={"text_credit_color"}
                              Amount={`Rs ${formattedAmount}`}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <FallBack
                    heading={"No Payout available"}
                    title={"Payout list will be shown here"}
                    // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                  />
                )}
              </InfiniteScroll>
            </>
          )}
        </div>
        {/* <ReportSidebar /> */}
      </div>
    </div>
  );
}

export default PayoutListScreen;
