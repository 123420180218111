import React from "react";
import {
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
} from "../../components";

import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import Modal from "react-modal";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import campaign from "../../common/data/campaign";
import { isMobile } from "react-device-detect";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    paddingLeft: 30,
    width: "368px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

function CreateCollectionModal({ closeModal, getCollectionList, campaignId }) {
  const [collectionName, setCollectionName] = useState("");
  const [loading, setLoading] = useState(false);

  const redux_dispatch = useDispatch();

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function createCollectionList() {
    var collection_data = {
      campaign_id: campaignId ? campaignId : null,
      brand_id: brand_data?.brand_id,
      org_id: brand_data?.org_id,
      collection_name: collectionName,
    };
    const params = {
      collection_data: JSON.stringify(collection_data),
    };
    Core.postAddCollection(
      createCollectionSuccess,
      createCollectionListFailed,
      (status) => setLoading(status),
      params
    );
  }

  function createCollectionSuccess(response_data) {
    getCollectionList();
    closeModal();
    toast.success("Create Collection Successfully");
  }

  function createCollectionListFailed(errorList) {
    toast.error(errorList);
  }
  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={customStylesModal}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 54,
        }}>
        <div className="">
          <CustomText
            h3
            text={"Create a new collection"}
            textColor={"text_grey_400_color"}
            className="typography-centerH7"
          />
        </div>
        <div onClick={closeModal} className="close-btn-icon">
          <Icon iconName="CloseRoundButtonIcon" />
        </div>
      </div>
      <div style={{ paddingTop: 54 }}>
        <div className="m-t-10">
          <div style={{ textAlign: "center" }} className="flex-row">
            <CustomText
              h5
              fontSize={"font_size_large"}
              text={"Collection Name"}
              textColor={"text_grey_400_color"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
              className="m-b-8 m-l-10"
            />
          </div>
          <CustomTextInput
            className={"input-height-counter"}
            value={collectionName}
            onChange={(e) => setCollectionName(e.target.value)}
            placeHolder={"collection name"}
            type="text"
          />
        </div>

        <div className="m-t-25">
          <PrimaryCta
            fifth
            onClick={() => createCollectionList()}
            fontSize={"font_size_large"}
            className="collection-create-btn-2 text-no-warp"
            text={"Create Collection"}
            isLoading={loading}
            // disabled={props.disabled}
          />

          {/* <PrimaryCta
          invert
          onClick={props.onClose}
          className="confirm-btn-bid"
          fontSize={"font_size_large"}
          text={"Cancel"}
          disabled={props.disabled}
        /> */}
          {!isMobile && (
            <button
              style={{ height: 44 }}
              className="confirm-btn-bid  m-l-10"
              onClick={closeModal}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CreateCollectionModal;
