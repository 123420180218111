import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../sideMenu";
import Header from "../header";
import { CustomText, CustomTextInput, PrimaryCta } from "../../components";
import Core from "../../common/clientSdk";
import { data } from "jquery";
import validator from "validator";
import countryList from "../../common/data/country";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import * as Actions from "../../redux/actions";
import Select from "react-dropdown-select";

function UserCountry() {
  const [isLoading, setLoading] = useState(false);
  const [changeCountry, setChangeCountry] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [country, setCountry] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [error, set_error] = useState({
    designation: "",
  });
  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;

  const { user_data = {} } = BrandUser;

  useEffect(() => {
    // Set the initial selected value based on idToShow
    if (user_data?.country) {
      const initialValue = Object.values(countryList).find(
        (option) => option.country_code === user_data.country
      );
      console.log("initialValue", initialValue);
      if (initialValue) setCountrySelected([initialValue]);
    }
  }, [user_data?.country]);

  useEffect(() => {
    setChangeCountry(user_data.designation);
  }, []);

  function postUpdateUserProfile(progress_cb) {
    const params = {
      user_data: JSON.stringify({
        org_id: user_data.org_id,
        user_id: user_data.user_id,
        country: country.toString(),
      }),
    };

    Core.postUpdateUserProfile(
      postUpdateUserProfileSuccess,
      postUpdateUserProfileFailed,
      progress_cb,
      params
    );
  }

  function postUpdateUserProfileSuccess(response_data) {
    setLoading(false);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });
    openSnackbar("Country changed successfully!");
    navigate("/user-settings");
  }

  function postUpdateUserProfileFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function updateCounty(selectedItem) {
    console.log("selectedItem", selectedItem);

    setCountrySelected(selectedItem);
    setCountry(
      selectedItem ? selectedItem.map((option) => option.country_code) : []
    );
  }

  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, designation: "Please enter name" });
    } else {
      set_error({ ...error, designation: "" });
      return true;
    }
  }
  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"User Settings"}
              textColor={"text_color_light"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="content-row-parent flex-row">
            <div style={{ width: 369 }} className="">
              <Select
                multi={false}
                options={Object.values(countryList)}
                values={countrySelected}
                onChange={(value) => updateCounty(value)}
                placeholder="Select County"
                labelField="name"
                valueField="country_code"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid rgb(230, 231, 232)",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  paddingBottom: "16px",
                  paddingTop: "16px",
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
              <h5 className="error">{error.designation}</h5>
            </div>
            <div className="m-l-15">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Update"}
                isLoading={isLoading}
                onClick={() => {
                  postUpdateUserProfile(updateProgress);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCountry;
