import React from "react";
import {
  TransitionHistory,
  CustomText,
  FallBack,
  CustomTextInput,
  PrimaryCta,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState, useEffect } from "react";
import Core from "../../common/clientSdk";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import { useSelector } from "react-redux";
import utils from "../../common/utils";
import CustomTable from "../../components/customTable";
import Papa from "papaparse";
import exportToCSV from "../../common/exportToCSV";
import { ToastContainer, toast } from "react-toastify";
import CompanySettingModal from "../companyDetails/addressDetailVerify";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Utils from "../../common/utils";

const limit = 1;
const CreditHistoryScreen = React.memo((props) => {
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [creditHistory, setCreditHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [addAmount, setAddAmount] = useState("10000");
  const [paymentId, setPaymentId] = useState("");
  const [balance, setBalance] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [address, setAddress] = useState({});
  const [openSnackbar] = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    getOrgDataUser();
    getCreditsTransactionList(start, updateProgress);
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_data = {} } = BrandUser.org_deep_data;
  const { user_data = {} } = BrandUser;

  function getOrgDataUser() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
    };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      () => {},
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddress(response_data.org_data.address_data.address_premise);
    setBalance(response_data?.org_data?.org_data?.balance);
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    openSnackbar(errorList);
  }

  function updateProgress(status) {}

  function getCreditsTransactionList(startPage, progress_cb) {
    var params = {
      //   limit: limit,
      //   start: startPage,
    };
    Core.getCreditsTransactionList(
      getCreditsTransactionListSuccess,
      getCreditsTransactionListFailed,
      progress_cb,
      params
    );
  }

  function getCreditsTransactionListSuccess(response_data) {
    setCreditHistory(response_data["credit_list"]);
  }

  function getCreditsTransactionListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function redirectContractDetail(assignmentId) {
    navigate(`/contracts/${assignmentId}`);
  }

  const tableSchema = [
    {
      name: "Transaction ID",
      selector: (row) => row["assignment_id"]?.toUpperCase(),
      omit: isMobile,
      cell: (data) => (
        <span onClick={() => redirectContractDetail(data["transaction_id"])}>
          {data["transaction_id"] ? data["transaction_id"]?.toUpperCase() : "-"}
        </span>
      ),
    },

    {
      name: "Credit",
      selector: (row) => row["credits"],
    },
    {
      name: "",
      selector: (row) => row,

      cell: (data) => <span>{Utils.creditTypeSpent(data.credit_type)}</span>,
    },
    {
      name: "Date",

      selector: (row) => {
        return utils.convertUnixToDate(row.credit_date, user_data.time_zone);
      },
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "var(--general-secondary-color)",
        color: "var(--gray-gray-300)",
        fontSize: "16px",
        fontWeight: 600,
        lineHight: "19.2px",
        border: "none",
      },
    },
    headCells: {
      style: {
        padding: "10px 0px 10px 20px",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHight: "19.2px",
        "&:hover": {
          backgroundColor: "var(--general-secondary-color)",
          fontWeight: 600,
        },
      },
    },
    cells: {
      style: {
        paddingLeft: "20px",
      },
    },
  };

  let gst_value = (addAmount * 18) / 100;
  let total_pay = Number(addAmount) + Number(gst_value);

  const TransactionCSV = () => {
    const data = creditHistory.map((item) => {
      return {
        transaction_id: item.transaction_id,
        transaction_amount: item.transaction_amount,
        transaction_type: item.transaction_type,
        transaction_date: utils.convertUnixToDate(
          item.transaction_date,
          user_data.time_zone
        ),
        influencer_id: null,
      };
    });

    return (
      <div>
        <PrimaryCta
          sixth
          textColor={"text_color_primary"}
          iconName={"downloadIcon"}
          fontSize={"font_size_large"}
          className="transaction-csv-btn"
          text={"Export to CSV"}
          onClick={() => exportToCSV(data)}
        />
      </div>
    );
  };

  function openModal() {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  if (!props.isActive) {
    return <></>;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="custom-table">
          <CustomTable
            className="CustomTable"
            tableSchema={tableSchema}
            tableData={creditHistory}
            paginationPerPage={15}
            rowPaddingTop={16}
            rowPaddingBottom={16}
            // progressPending={loading}
            customStyles={customStyles}
            // paginationServer
            // paginationTotalRows={next}
            // onChangeRowsPerPage={getTransactionHistoryList}
            // onChangePage={handlePageChange}
            pagination
          />
        </div>
      )}
    </>
  );
});

export default CreditHistoryScreen;
