import React, { useState, useEffect } from "react";
import {
  CustomText,
  FallBack,
  ModalComponent,
  PrimaryCta,
  TeamMembers,
  TransitionHistory,
} from "../../components";
import Icon from "../../components/core_component/icon";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useNavigate } from "react-router-dom";
import Core from "../../common/clientSdk";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
import Modal from "react-modal";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SubscriptionModal from "../../modal/subscriptionModal";
function TeamMembersList() {
  const [memberList, setMemberList] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isLoadingRemove, setLoadingRemove] = useState(false);
  const [checkOrgSubUser, setCheckOrgSubUser] = useState({});
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false);
  const [inviteId, setInviteId] = useState(null);
  const [openSnackbar] = useSnackbar();
  console.log("subscriptionList", subscriptionList);

  useEffect(() => {
    getMembersList(updateProgress);
    userSelfData();
  }, []);

  function updateProgress(status) {}

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;

  console.log("brand_deep_data", brand_deep_data);

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSubUser(response?.org_data?.subscription_data);
  }
  function userSelfDataFailed() {}

  function getMembersList(progress_cb) {
    var params = {};
    Core.getMembersList(
      getMembersListSuccess,
      getMembersListFailed,
      progress_cb,
      params
    );
  }

  function getMembersListSuccess(response_data) {
    setMemberList(response_data.members_data);
    setLoading(false);
  }

  function getMembersListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function postRemoveMembers() {
    var params = {
      invite_id: inviteId,
    };
    Core.postRemoveMembers(
      postRemoveMembersSuccess,
      postRemoveMembersFailed,
      (status) => setLoadingRemove(status),
      params
    );
  }

  function postRemoveMembersSuccess(response_data) {
    console.log("response_data preve", response_data);
    setMemberList((prev) => {
      return prev.filter((member) => member.invite_id !== inviteId);
    });
    removeMemberModal();
  }

  function postRemoveMembersFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  var navigate = useNavigate();

  function maxBidCountCheck() {
    if (checkOrgSubUser?.feature?.max_brand_count) {
      return checkOrgSubUser?.feature?.max_brand_count;
    }
    return 0;
  }

  console.log("checkOrgSubUser 2", checkOrgSubUser);
  function checkSubscriptionOrNot() {
    if (!checkOrgSubUser?.feature?.max_brand_count) {
      openModal();
    } else if (memberList?.length >= 3) {
      toast.info("you can invite only 3 members");
    } else {
      navigate("/invite-member");
    }
  }

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  function removeMemberModal(id) {
    setModalIsOpenRemove(!modalIsOpenRemove);
    setInviteId(id);
  }
  function ActionOnLoadHandler(subscription_list) {
    setSubscriptionList(subscription_list);
  }

  function renderRemoveMember() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={removeMemberModal}
        style={CancelReminder}
      >
        <div
          onClick={removeMemberModal}
          className="close-btn-icon-subscription"
        >
          <Icon iconName="CrossBtnIcon" />
        </div>

        <div className="btn-accept-reject">
          <div className="confirm-cancelliation">
            <h2 className="cancel-text">Remove Member</h2>
            <p className="cancel-request m-b-10">
              Are you sure, you want to remove this member?
            </p>
            <div className="m-t-20">
              <PrimaryCta
                third
                onClick={() => postRemoveMembers()}
                fontSize={"font_size_regular"}
                className="create-campaign"
                text={"Remove"}
                isLoading={isLoadingRemove}
              />

              <button
                className="confirm-btn-bid  m-l-32"
                onClick={() => removeMemberModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <>
      <div className="dashboard-container">
        <SideMenu />
        <div className="main-screen">
          <Header
            onLoadHeader={(subscription_list) =>
              ActionOnLoadHandler(subscription_list)
            }
          />
          <div className="page-container ">
            {isLoading ? (
              <div className="loader-mid">
                <Loader />
              </div>
            ) : (
              <>
                <div className="team-member-header">
                  <CustomText
                    p
                    text={"Team Members"}
                    textColor={"text_color_primary"}
                    fontSize={"font_size_2xl"}
                  />
                  <PrimaryCta
                    third
                    fontSize={"font_size_regular"}
                    className="create-campaign"
                    text={"Invite Member"}
                    onClick={() => checkSubscriptionOrNot()}
                  />
                </div>
                {memberList && memberList?.length == 0 ? (
                  <div className="fall-back-center-campaign">
                    <FallBack
                      heading={"No Member available"}
                      title={"Active member will be shown here"}
                      // subTitle={"elit, sed do eiusmod tempor incididunt. ipasum"}
                    />
                  </div>
                ) : (
                  <div class="content-row-parent">
                    {memberList &&
                      memberList?.map((item) => {
                        return (
                          <div className="content-row">
                            <TeamMembers
                              invert
                              source={require("../../resources/images/fallback.png")}
                              Name={item.name}
                              title={"Member"}
                              Email={item.email}
                              stateColor={""}
                              iconName={"deleteNewIcon"}
                              onClick={() => removeMemberModal(item.invite_id)}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
          {modalIsOpenRemove && renderRemoveMember()}
          {modalIsOpen && (
            <SubscriptionModal
              subscriptionData={subscriptionList}
              closeModal={closeModal}
              orgData={org_deep_data?.org_data}
            />
          )}
          {/* <ReportSidebar /> */}
        </div>
      </div>
    </>
  );
}

export default TeamMembersList;
const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    height: "35%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
    padding: "0px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
