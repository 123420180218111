import React from "react";

// Import Redux State (Private scope)
import { useDispatch } from "react-redux";

import * as Actions from "../redux/actions";

import { CustomText, Image, MenuItem } from "../components";
import { NavLink, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
// Import Redux State (Private scope)
import { useSelector } from "react-redux";

import {
  CampaignsIcon,
  ReportsIcon,
  DashboardIcon,
  ManageTeamIcon,
  SettingIcon,
  TransactionIcon,
  UserIcon,
  FilterIcon,
} from "../components/assets/icons";
import { useState } from "react";
import { useEffect } from "react";
import Core from "../common/clientSdk";
import Sdk from "../common/core/module";
import Icon from "../components/core_component/icon";
import WebStorage from "../common/web_storage";
import { BRAND_ID_KEY } from "../common/web_storage_keys";
import { useSnackbar } from "react-simple-snackbar";

const SideMenu = function (props) {
  const [userData, setUserdata] = useState({});
  const [assignmentList, setAssignmentList] = useState([]);
  const [openSnackbar] = useSnackbar();
  const [brandList, setBrandList] = useState([]);

  // Initialize Private Functions
  var redux_dispatch = useDispatch();

  // start

  // useEffect(() => {
  //   getBrandList();
  // }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    getAssignmentBrandDetails();
  }, []);

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;
  const { role = "ADMIN" } = user_data;

  function getAssignmentBrandDetails() {
    var params = {
      brand_id: redux_selector.BrandUser.brand_deep_data.brand_data.brand_id,
    };

    Core.getAssignmentBrandDetails(
      getAssignmentBrandDetailsSuccess,
      getAssignmentBrandDetailsFailed,
      updateProgress,
      params
    );
  }

  function getAssignmentBrandDetailsSuccess(response_data) {
    setAssignmentList(response_data["assignment_list"]);
  }

  function getAssignmentBrandDetailsFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function getBrandList() {
    var params = {
      org_id: Sdk.User.getUserOrgId(redux_selector.BrandUser.user_data),
    };
    Core.brandList(brandListSuccess, brandListFailed, progress_cb, params);
  }

  function brandListSuccess(response) {
    setBrandList(response.brand_list);
  }

  function brandListFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  function progress_cb(status) {}

  function getBrandData(progress_cb, brand_id) {
    var params = {
      brand_id: brand_id,
      org_id: Sdk.User.getUserOrgId(redux_selector.BrandUser.user_data),
    };
    Core.getBrandData(
      getBrandDataSuccess,
      getBrandDataFailed,
      progress_cb,
      params,
      redux_selector.Auth.auth_token
    );
  }

  function getBrandDataSuccess(response_data) {
    //update brand data in redux
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.b_d },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.b_d.brand_data.brand_id
    );
  }

  function getBrandDataFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {}

  let pendingMessage = assignmentList.filter(
    (item) => item?.assignment_data?.unseen_brand == 1
  );

  //end

  const menu_item_data_admin = [
    {
      title: "Dashboard",
      svgIconName: "DashboardIcon",
      svgIconNameActive: "DashboardIconActive",
      navlink: "/",
    },
    {
      title: "Database",
      svgIconName: "discoveryInfluencerIcon",
      svgIconNameActive: "discoveryInfluencerActiveIcon",
      navlink: "/influencer-list",
    },
    {
      title: "Campaigns",
      svgIconName: "CampaignsIcon",
      svgIconNameActive: "CampaignsIconActive",
      navlink: "/campaigns",
    },

    {
      title: "Contracts",
      svgIconName: "contractIcon",
      svgIconNameActive: "contractIconActive",
      navlink: "/contracts",
    },
    {
      title: "Creatives",
      svgIconName: "creativesIcons",
      svgIconNameActive: "creativesIconsActive",
      navlink: "/content-view",
    },
    {
      title: "Collections",
      svgIconName: "collectionIcons",
      svgIconNameActive: "collectionIconsActive",
      navlink: "/collection",
    },
    {
      title: "Chats",
      svgIconName: "chatInactiveSideBarIcon",
      svgIconNameActive: "chatIConsSideBar",
      navlink: "/message",
      hasPendingMessages: pendingMessage.length > 0,
    },
    {
      title: "Wallet",
      svgIconName: "TransactionIcon",
      svgIconNameActive: "TransactionIconActive",
      navlink: "/transaction-list",
    },
    {
      title: "Brand",
      svgIconName: "chatInactiveSideBarIcon",
      svgIconNameActive: "chatIConsSideBar",
      navlink: "/brand-analytics",
    },
    // {
    //   title: "Profile",
    //   svgIconName: "UserProfileIcon",
    //   svgIconNameActive: "UserProfileIconActive",
    //   navlink: "/user-settings",
    // },
    // {
    //   title: "Script",
    //   svgIconName: "UserProfileIcon",
    //   svgIconNameActive: "UserProfileIconActive",
    //   navlink: "/script",
    // },

    // {
    //   title: "Brand Settings",
    //   svgIconName: "BrandSetting",
    //   svgIconNameActive: "BrandSettingActive",
    //   navlink: "/brand-settings",
    // },

    // {
    //   title: "Team",
    //   svgIconName: "ManageTeamIcon",
    //   svgIconNameActive: "ManageTeamIconActive",
    //   navlink: "/team-members-list",
    // },
    // {
    //   title: "Company",
    //   svgIconName: "CompanyIcon",
    //   svgIconNameActive: "CompanyIconActive",
    //   navlink: "/settings",
    // },
  ];

  const menu_item_data = [
    {
      title: "Dashboard",
      svgIconName: "DashboardIcon",
      svgIconNameActive: "DashboardIconActive",
      navlink: "/",
    },
    {
      title: "Database",
      svgIconName: "discoveryInfluencerIcon",
      svgIconNameActive: "discoveryInfluencerActiveIcon",
      navlink: "/influencer-list",
    },
    {
      title: "Campaigns",
      svgIconName: "CampaignsIcon",
      svgIconNameActive: "CampaignsIconActive",
      navlink: "/campaigns",
    },

    {
      title: "Contracts",
      svgIconName: "contractIcon",
      svgIconNameActive: "contractIconActive",
      navlink: "/contracts",
    },
    {
      title: "Creatives",
      svgIconName: "creativesIcons",
      svgIconNameActive: "creativesIconsActive",
      navlink: "/content-view",
    },
    {
      title: "Collections",
      svgIconName: "collectionIcons",
      svgIconNameActive: "collectionIconsActive",
      navlink: "/collection",
    },

    {
      title: "Transactions",
      svgIconName: "TransactionIcon",
      svgIconNameActive: "TransactionIconActive",
      navlink: "/transaction-list",
    },
    {
      title: "Payout",
      svgIconName: "PayoutIcon",
      svgIconNameActive: "PayoutIconActive",
      navlink: "/payout-list",
    },
  ];

  const location = useLocation();
  const currentPath = "/" + location.pathname.split("/")[1];

  // const MenuItem = function (props) {
  //   const [isHovered, setIsHovered] = useState(false);

  //   const handleMouseEnter = () => {
  //     setIsHovered(true);
  //   };

  //   const handleMouseLeave = () => {
  //     setIsHovered(false);
  //   };
  //   return (
  //     <li key={props.index} className={"menu-text"}>
  //       <NavLink
  //         to={props.navlink}
  //         onClick={props.onClick}
  //         onMouseEnter={handleMouseEnter}
  //         onMouseLeave={handleMouseLeave}
  //       >
  //         <Icon
  //           iconName={
  //             props.navlink == currentPath || isHovered
  //               ? props.svgIconNameActive
  //                 ? props.svgIconNameActive
  //                 : props.svgIconName
  //               : props.svgIconName
  //           }
  //           //className={"menu-icon"}
  //           isActive={props.navlink == currentPath ? true : false}
  //         />

  //         <span>
  //           <CustomText
  //             p
  //             text={props.title}
  //             fontSize={"font_size_large"}
  //             textAlign={"text_align_left"}
  //             fontWeight={"Inter_bold"}
  //           />
  //         </span>
  //       </NavLink>
  //     </li>
  //   );
  // };

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  return (
    <div className={!props.showItem ? "side-menu" : "side-menu-hide"}>
      {/* dropdown Top for brand` */}
      <div className="dropdown">
        <div className="menu-text-dropdown">
          {/* <Image source={"../assets/images/reelax-new-logo.svg"} height={50} /> */}
          {/* <Icon
            iconName={"ProfileDropDownIcon"}
            height={7.41}
            width={12}
            fill="#FFF"
          /> */}
        </div>

        {/* <div className="dropdown-content">
          <ul className="menu-items">
            {brandList.map((item, index) => {
              return (
                <li key={index} className={"menu-text"}>
                  <NavLink
                    to={"#"}
                    onClick={() =>
                      getBrandData(progress_cb, item.brand_data.brand_id)
                    }>
                    <span>
                      <CustomText
                        p
                        text={item.brand_data.name}
                        fontSize={"font_size_large"}
                        textAlign={"text_align_left"}
                        fontWeight={"rubik_regular"}
                        textColor={"text_color_tertiary"}
                      />
                    </span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>

      <div className="section">
        <div className="main-menu">
          <ul className="menu-items">
            {role == "ADMIN" && (
              <>
                {menu_item_data_admin.map((items, index) => {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      navlink={items.navlink}
                      currentPath={currentPath}
                      hasPendingMessages={items.hasPendingMessages}
                    />
                  );
                })}
              </>
            )}
            {role == "MEMBER" && (
              <>
                {menu_item_data.map((items, index) => {
                  return (
                    <MenuItem
                      key={index}
                      svgIconName={items.svgIconName}
                      svgIconNameActive={items.svgIconNameActive}
                      title={items.title}
                      navlink={items.navlink}
                      currentPath={currentPath}
                    />
                  );
                })}
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
