import { useEffect, useState } from "react";
import Modal from "react-modal";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import Core from "../common/clientSdk";
import { toast } from "react-toastify";
import Toggle from "react-toggle";

export const CollectionInfluencerShareLink = ({
  closeShareModal,
  collectionId,
  shareKey,
  refreshHandler,
  collectionData,
}) => {
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [hidePrice, setHidePrice] = useState(collectionData.show_price);

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (!shareKey) {
      generateShareKey();
    } else {
      setUrl(shareKey);
    }
  }, []);

  function generateShareKey() {
    var params = {
      collection_id: collectionId,
    };
    Core.generateShareKey(
      generateShareKeySuccess,
      generateShareKeyFailed,
      (status) => setLoading(status),
      params
    );
  }
  function generateShareKeySuccess(response) {
    setUrl(response.share_key);
    refreshHandler();
  }

  function generateShareKeyFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }

  function postCollectionUpdate(hide_price) {
    let collection_data = {
      collection_id: collectionId,
      show_price: hide_price,
    };
    var params = {
      collection_data: JSON.stringify(collection_data),
    };
    Core.postCollectionUpdate(
      postCollectionUpdateSuccess,
      postCollectionUpdateFailed,
      (status) => setLoading(status),
      params
    );
  }
  function postCollectionUpdateSuccess(response) {
    console.log("response sss", response);
  }

  function postCollectionUpdateFailed(error) {
    toast.error(error.map((item) => item.m).join(","));
  }
  // let hostName = `https://${window.location.host}/collection/share/${share_key}`;

  function constructUrl(share_key) {
    return `https://${window.location.host}/collection/share/${share_key}`;
  }
  const copyToClipboard = () => {
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    navigator.clipboard
      .writeText(constructUrl(url))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset "copied" status after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  function onPressHandlerShowPriceToggle() {
    if (hidePrice || hidePrice == 1) {
      setHidePrice(0);
      postCollectionUpdate(0);
    } else {
      setHidePrice(1);
      postCollectionUpdate(1);
    }
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={closeShareModal}
      style={CancelReminder}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 30,
          paddingTop: 15,
        }}
      >
        <div className="collection-link-copy-header">
          <CustomText
            h5
            text={"Collection"}
            textColor={"text_grey_400_color"}
            className="m-b-20 typography-centerH7"
          />
          <div onClick={closeShareModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
      </div>
      <div className="share-collection-popout" style={{ paddingTop: 40 }}>
        <div className="text-align-start">
          <Toggle
            className="custom-toggle"
            checked={hidePrice == 1 ? true : false}
            icons={false}
            onChange={() => onPressHandlerShowPriceToggle()}
          />
        </div>
        <div className="collection-sheet-url-container">
          <CustomTextInput
            invert
            value={
              loading
                ? "generating url..."
                : `https://${window.location.host}/collection/share/${url}`
            }
            placeholder={"e.g. https://mystore.com/collection"}
            //   onChange={(e) => setProductUrl(e.target.value)}
            disabled
          />
          <PrimaryCta
            fifth
            onClick={() => copyToClipboard()}
            text={copied ? "Copied!" : "Copy link"}
            className="copy-cta-btn"
          />
        </div>

        <div className="collection-link-regenerate">
          <CustomText
            p
            text={"Disable/Regenerate previously generated link:"}
            className="button1"
            textColor="text_grey_100_color"
          />
          <div
            onClick={generateShareKey}
            className="share-icon-regenrate cursor-pointer"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="regenerate-url m-t-5 ">
              <Icon iconName="regenerateUrlIcon" />
            </div>
            <div>
              <CustomText
                p
                text={"Regenerate"}
                className="button-text m-l-5"
                textColor="text_grey_100_color"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    textAlign: "center",
    zIndex: "5",
    // height: "35%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    minHeight: "150px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
