module.exports = {
  US: {
    name: "United States",
    calling_code: "+1",
    country_code: "US",
    currency: { code: "USD", name: "United States Dollar", symbol: "$" },
    timezones: [
      "America/New_York", // Eastern Time
      "America/Chicago", // Central Time
      "America/Denver", // Mountain Time
      "America/Phoenix", // Mountain Time (no daylight saving)
      "America/Los_Angeles", // Pacific Time
      "America/Anchorage", // Alaska Time
      "Pacific/Honolulu", // Hawaii-Aleutian Time
    ],
  },
  IN: {
    name: "India",
    calling_code: "+91",
    country_code: "IN",
    currency: { code: "INR", name: "Indian Rupee", symbol: "₹" },
    timezones: ["Asia/Kolkata"],
  },
  //   AD: {
  //     name: "Andorra",
  //     calling_code: "+376",
  //     country_code: "AD",
  //     currency: { code: "EUR", name: "Euro", symbol: "€" },
  //   },
  //   AE: {
  //     name: "United Arab Emirates",
  //     calling_code: "+971",
  //     country_code: "AE",
  //     currency: {
  //       code: "AED",
  //       name: "United Arab Emirates Dirham",
  //       symbol: "د.إ",
  //     },
  //   },
  //   AF: {
  //     name: "Afghanistan",
  //     calling_code: "+93",
  //     country_code: "AF",
  //     currency: { code: "AFN", name: "Afghan Afghani", symbol: "؋" },
  //   },
  //   AG: {
  //     name: "Antigua and Barbuda",
  //     calling_code: "+1-268",
  //     country_code: "AG",
  //     currency: { code: "XCD", name: "East Caribbean Dollar", symbol: "$" },
  //   },
  //   AI: {
  //     name: "Anguilla",
  //     calling_code: "+1-264",
  //     country_code: "AI",
  //     currency: { code: "XCD", name: "East Caribbean Dollar", symbol: "$" },
  //   },
  //   AL: {
  //     name: "Albania",
  //     calling_code: "+355",
  //     country_code: "AL",
  //     currency: { code: "ALL", name: "Albanian Lek", symbol: "L" },
  //   },
  //   AM: {
  //     name: "Armenia",
  //     calling_code: "+374",
  //     country_code: "AM",
  //     currency: { code: "AMD", name: "Armenian Dram", symbol: "֏" },
  //   },
  //   AO: {
  //     name: "Angola",
  //     calling_code: "+244",
  //     country_code: "AO",
  //     currency: { code: "AOA", name: "Angolan Kwanza", symbol: "Kz" },
  //   },
  //   AQ: {
  //     name: "Antarctica",
  //     calling_code: "",
  //     country_code: "AQ",
  //     currency: { code: "", name: "None", symbol: "" },
  //   },
  //   AR: {
  //     name: "Argentina",
  //     calling_code: "+54",
  //     country_code: "AR",
  //     currency: { code: "ARS", name: "Argentine Peso", symbol: "$" },
  //   },
  //   AS: {
  //     name: "American Samoa",
  //     calling_code: "+1-684",
  //     country_code: "AS",
  //     currency: { code: "USD", name: "United States Dollar", symbol: "$" },
  //   },
  //   AT: {
  //     name: "Austria",
  //     calling_code: "+43",
  //     country_code: "AT",
  //     currency: { code: "EUR", name: "Euro", symbol: "€" },
  //   },
  //   AU: {
  //     name: "Australia",
  //     calling_code: "+61",
  //     country_code: "AU",
  //     currency: { code: "AUD", name: "Australian Dollar", symbol: "$" },
  //   },
  //   AW: {
  //     name: "Aruba",
  //     calling_code: "+297",
  //     country_code: "AW",
  //     currency: { code: "AWG", name: "Aruban Florin", symbol: "ƒ" },
  //   },
  //   AX: {
  //     name: "Aland Islands",
  //     calling_code: "+358",
  //     country_code: "AX",
  //     currency: { code: "EUR", name: "Euro", symbol: "€" },
  //   },
  //   AZ: {
  //     name: "Azerbaijan",
  //     calling_code: "+994",
  //     country_code: "AZ",
  //     currency: { code: "AZN", name: "Azerbaijani Manat", symbol: "₼" },
  //   },
  //   BA: {
  //     name: "Bosnia and Herzegovina",
  //     calling_code: "+387",
  //     country_code: "BA",
  //     currency: {
  //       code: "BAM",
  //       name: "Bosnia-Herzegovina Convertible Mark",
  //       symbol: "KM",
  //     },
  //   },
  //   BB: {
  //     name: "Barbados",
  //     calling_code: "+1-246",
  //     country_code: "BB",
  //     currency: { code: "BBD", name: "Barbadian Dollar", symbol: "$" },
  //   },
  //   BD: {
  //     name: "Bangladesh",
  //     calling_code: "+880",
  //     country_code: "BD",
  //     currency: { code: "BDT", name: "Bangladeshi Taka", symbol: "৳" },
  //   },
  //   BE: {
  //     name: "Belgium",
  //     calling_code: "+32",
  //     country_code: "BE",
  //     currency: { code: "EUR", name: "Euro", symbol: "€" },
  //   },
  //   BF: {
  //     name: "Burkina Faso",
  //     calling_code: "+226",
  //     country_code: "BF",
  //     currency: { code: "XOF", name: "West African CFA Franc", symbol: "CFA" },
  //   },
  //   BG: {
  //     name: "Bulgaria",
  //     calling_code: "+359",
  //     country_code: "BG",
  //     currency: { code: "BGN", name: "Bulgarian Lev", symbol: "лв" },
  //   },
  //   BH: {
  //     name: "Bahrain",
  //     calling_code: "+973",
  //     country_code: "BH",
  //     currency: { code: "BHD", name: "Bahraini Dinar", symbol: ".د.ب" },
  //   },
  //   BI: {
  //     name: "Burundi",
  //     calling_code: "+257",
  //     country_code: "BI",
  //     currency: { code: "BIF", name: "Burundian Franc", symbol: "FBu" },
  //   },
  //   BJ: {
  //     name: "Benin",
  //     calling_code: "+229",
  //     country_code: "BJ",
  //     currency: { code: "XOF", name: "West African CFA Franc", symbol: "CFA" },
  //   },
  //   BL: {
  //     name: "Saint Barthelemy",
  //     calling_code: "+590",
  //     country_code: "BL",
  //     currency: { code: "EUR", name: "Euro", symbol: "€" },
  //   },
  //   BM: {
  //     name: "Bermuda",
  //     calling_code: "+1-441",
  //     country_code: "BM",
  //     currency: { code: "BMD", name: "Bermudian Dollar", symbol: "$" },
  //   },
  //   BN: {
  //     name: "Brunei Darussalam",
  //     calling_code: "+673",
  //     country_code: "BN",
  //     currency: { code: "BND", name: "Brunei Dollar", symbol: "$" },
  //   },
  //   BO: {
  //     name: "Bolivia",
  //     calling_code: "+591",
  //     country_code: "BO",
  //     currency: { code: "BOB", name: "Bolivian Boliviano", symbol: "Bs." },
  //   },
};
